var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "match-height" },
    [
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-layout-horizontal")], 1),
      _c(
        "b-col",
        { attrs: { lg: "6" } },
        [_c("form-layout-horizontal-icon")],
        1
      ),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-layout-vertical")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-layout-vertical-icon")], 1),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [_c("form-layout-multiple-column")],
        1
      ),
      _c(
        "b-col",
        { attrs: { lg: "6" } },
        [_c("form-layout-datalist-helper")],
        1
      ),
      _c(
        "b-col",
        { attrs: { lg: "6" } },
        [_c("form-layout-feedback-helper")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }