var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Horizontal Form With Icons" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeHorizontalIcon) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "First Name",
                        "label-for": "hi-first-name",
                        "label-cols-md": "4"
                      }
                    },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "input-group-merge" },
                        [
                          _c(
                            "b-input-group-prepend",
                            { attrs: { "is-text": "" } },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "UserIcon" }
                              })
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            attrs: {
                              id: "hi-first-name",
                              placeholder: "First Name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Email",
                        "label-for": "hi-email",
                        "label-cols-md": "4"
                      }
                    },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "input-group-merge" },
                        [
                          _c(
                            "b-input-group-prepend",
                            { attrs: { "is-text": "" } },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "MailIcon" }
                              })
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            attrs: {
                              id: "hi-email",
                              type: "email",
                              placeholder: "Email"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Mobile",
                        "label-for": "hi-number",
                        "label-cols-md": "4"
                      }
                    },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "input-group-merge" },
                        [
                          _c(
                            "b-input-group-prepend",
                            { attrs: { "is-text": "" } },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "SmartphoneIcon" }
                              })
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            attrs: {
                              id: "hi-number",
                              type: "number",
                              placeholder: "Mobile"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Password",
                        "label-for": "hi-password",
                        "label-cols-md": "4"
                      }
                    },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "input-group-merge" },
                        [
                          _c(
                            "b-input-group-prepend",
                            { attrs: { "is-text": "" } },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "LockIcon" }
                              })
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            attrs: {
                              id: "hi-password",
                              type: "password",
                              placeholder: "Password"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "8", "offset-md": "4" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "checkbox-1",
                            name: "checkbox-1",
                            value: "Remember_me"
                          }
                        },
                        [_vm._v(" Remember me ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { "offset-md": "4" } },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      staticClass: "mr-1",
                      attrs: { type: "submit", variant: "primary" }
                    },
                    [_vm._v(" Submit ")]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(186, 191, 199, 0.15)",
                          expression: "'rgba(186, 191, 199, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      attrs: { type: "reset", variant: "outline-secondary" }
                    },
                    [_vm._v(" Reset ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }